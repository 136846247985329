import React from 'react';

import { useAnimationScrollListener } from '@hub-fe/common/Animations/Animations';
import TermsAndConditions from '@hub-fe/common/terms/TermsAndConditions';
import { DamlHubHelmet, ResponsiveNav } from '@hub-fe/landing/nav';

const Terms: React.FC = () => {
    useAnimationScrollListener();

    return (
        <div className="roomy gatsby-terms default">
            <DamlHubHelmet title="Terms" />
            <ResponsiveNav showLogo />
            <header className="terms roomy">
                <h1>Terms and Conditions</h1>
                <TermsAndConditions />
            </header>
        </div>
    );
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default Terms;
